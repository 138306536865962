/*
 * @FileDescription:
 * @Author: ruokun Yin
 * @Date: 2022-04-18 11:00:58
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2022-12-08 15:12:49
 */

import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "zh_CN", // 定义默认语言为中文
  messages: {
    zh_CN: require("./modules/zh-cn.json"),
    en_US: require("./modules/en-us.json"),
  },
});

export default i18n;
