/*
 * @FileDescription:
 * @Author: ruokun Yin
 * @Date: 2022-07-26 11:10:41
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2024-03-28 10:46:09
 */

import axios from "axios";
import store from "@/store/index";

const instance = axios.create({
    baseURL: process.env.VUE_APP_PUBLIC_PATH || "/public",
    timeout: 3000000,
});

instance.interceptors.request.use((config) => {
    config.headers.appId = store.state.appid;

    return config;
});

instance.interceptors.response.use(async (response) => {
    return response.data;
});

export default instance;
