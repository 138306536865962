/*
 * @FileDescription:
 * @Author: ruokun Yin
 * @Date: 2022-11-17 15:30:56
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2024-03-07 18:32:56
 */
import Vue from "vue";
import Vuex from "vuex";
import { getUsageDetailById } from "@/api/index";
import { updateThemeColor, updateFavicon, updateTitle, getDetailByDefaultLan } from "@/utils/tools";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        winWidth: window.innerWidth,
        appid: "",
        wikiAppId: "",
        environment: "",
        defaultlanguage: "",
        defaultlanDetail: {},
        globalConfig: {},
        supportLanguage: [],
    },
    mutations: {
        SetWinWithd: (state, width) => {
            state.winWidth = width;
        },
        SetWikiAppId: (state, id) => {
            state.wikiAppId = id;
        },
        setAppid: (state, id) => {
            state.appid = id;
        },
        SetGlobalConfig: (state, detail) => {
            state.globalConfig = detail;
        },
        SetDefaultlanguage: (state, language) => {
            sessionStorage.setItem("wikiappDefaultLan", language);
            state.defaultlanguage = language;
        },
        SetDefaultlanDetail: (state, detail) => {
            state.defaultlanDetail = detail;
        },
        SetSupportLanguage: (state, languages) => {
            state.supportLanguage = languages;
        },
        setEnvironment: (state, environment) => {
            state.environment = environment;
        },
    },
    getters: {},
    actions: {
        // 获取站点详情
        getWikiDetail({ commit, state }, { wikiAppId, language }) {
            return new Promise((resolve, reject) => {
                // 设置wikiid
                commit("SetWikiAppId", wikiAppId);

                getUsageDetailById({ usageManageId: wikiAppId || state.wikiAppId })
                    .then((res) => {
                        if (res.code == 200) {
                            let languages = res.data.usageManageDetails.map((item) => item.usageLanguage);
                            commit("SetGlobalConfig", res.data);
                            commit("SetDefaultlanguage", language || sessionStorage.getItem("wikiappDefaultLan") || res.data.usageDefaultLanguage);
                            commit("SetSupportLanguage", languages);

                            // 设置主题颜色
                            updateThemeColor(res.data.usageThemeColor);
                            // 设置favicon
                            updateFavicon(res.data.usageFavicon);
                            // 获取当前语言下的detail
                            const wikiDetail = getDetailByDefaultLan(state.defaultlanguage, res.data.usageManageDetails);
                            // 更细浏览器title
                            updateTitle(wikiDetail.usageName);

                            commit("SetDefaultlanDetail", wikiDetail);

                            resolve(res.data);
                        } else {
                            reject();
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    modules: {},
});
