<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-11-17 15:30:56
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-05-18 19:09:59
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    this.$store.commit("SetWinWithd", window.innerWidth);
    window.onresize = () => this.$store.commit("SetWinWithd", window.innerWidth);
  },
};
</script>

<style lang="scss">
#app {
  font-family: PingFangSC-Regular, Avenir, Helvetica, Arial, sans-serif;
}
</style>
