/*
 * @FileDescription:
 * @Author: ruokun Yin
 * @Date: 2022-11-17 15:30:56
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2024-03-12 11:09:38
 */
import Vue from "vue";
import VueRouter from "vue-router";
import routerView from "@/views/routerView";
import store from "@/store";
import { getUsageId } from "@/api/index";
import { isMobile } from "@/utils/tools";
const path = isMobile() ? "/mobile" : "/pc";

Vue.use(VueRouter);

const routes = [
    // 必须放在首位
    {
        path: "/error",
        name: "Error",
        component: () => import(`@/views${path}/Error.vue`),
    },
    {
        path: "/",
        beforeEnter: async (to, from, next) => {
            let origin = location.origin;
            let customToken = to.query.customToken;
            let language = to.query.language;
            let environment = to.query.environment;
            if (environment) this.$store.commit("setEnvironment", environment);
            const { data } = await getUsageId({ linkUrl: origin, customToken });
            if (data) {
                try {
                    const { appId, usageSubdomain } = await store.dispatch("getWikiDetail", { wikiAppId: data, language });
                    next("/" + appId + "/" + usageSubdomain);
                } catch (error) {
                    next("/error");
                }
            } else {
                next("/error");
            }
        },
    },
    //  符合条件的就匹配
    {
        path: "/:appId",
        meta: {
            title: "一级路由",
        },
        component: routerView,
        beforeEnter: async (to, from, next) => {
            if (to.params.appId && to.params.usageSubdomain) {
                store.commit("setAppid", to.params.appId);
                next();
            } else {
                next("/error");
            }
        },
        children: [
            {
                path: ":usageSubdomain",
                component: () => import(`@/views${path}/index.vue`),
                beforeEnter: async (to, from, next) => {
                    if (store.state.wikiAppId) {
                        next();
                    } else {
                        let customToken = to.query.customToken;
                        let language = to.query.language;
                        let params = to.params;
                        const { data } = await getUsageId({ ...params, customToken });
                        if (data) {
                            try {
                                await store.dispatch("getWikiDetail", { wikiAppId: data, language });
                                next();
                            } catch (error) {
                                next("/error");
                            }
                        } else {
                            next("/error");
                        }
                    }
                },
                children: [
                    {
                        path: "",
                        name: "Home",
                        component: () => import(`@/views${path}/Home`),
                        meta: {
                            title: "首页",
                        },
                    },
                    {
                        path: "collections",
                        name: "Collections",
                        component: () => import(`@/views${path}/Collections.vue`),
                        meta: {
                            title: "分组列表",
                        },
                    },
                    {
                        path: "wikidetail/",
                        name: "Detail",
                        component: () => import(`@/views${path}/Detail.vue`),
                        meta: {
                            title: "详情页",
                        },
                    },

                    {
                        path: "search",
                        name: "Search",
                        component: () => import(`@/views${path}/Search`),
                        meta: {
                            title: "搜索",
                        },
                    },
                ],
            },
        ],
    },

    //   {
    //     path: "/:appId/:usageSubdomain",
    //     component: routerView,
    //     meta: {
    //       title: "一级路由",
    //     },
    //     redirect: {
    //       name: "Home",
    //     },
    //     children: [
    //       {
    //         path: "home",
    //         name: "Home",
    //         component: () => import(`@/views${path}/Home`),
    //         meta: {
    //           title: "首页",
    //         },
    //       },
    //       {
    //         path: "search",
    //         name: "Search",
    //         component: () => import(`@/views${path}/Search`),
    //         meta: {
    //           title: "搜索",
    //         },
    //       },
    //       {
    //         path: "listPages",
    //         name: "ListPages",
    //         component: () => import(`@/views${path}/ListPages`),
    //         meta: {
    //           title: "列表管理",
    //         },
    //       },
    //       {
    //         path: "detail",
    //         name: "Detail",
    //         component: () => import(`@/views${path}/Detail.vue`),
    //         meta: {
    //           title: "详情页",
    //         },
    //       },
    //       {
    //         path: "groupList",
    //         name: "GroupList",
    //         component: () => import(`@/views${path}/GroupList.vue`),
    //         meta: {
    //           title: "群组列表",
    //         },
    //       },
    //     ],
    //   },

    //   {
    //     path: "*",
    //     redirect: { name: "Error" },
    //     meta: {
    //       title: "error",
    //     },
    //   },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: () => ({ y: 0 }),
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

// let initParams = {};

// router.beforeEach(async (to, from, next) => {
//   console.log(from);
//   console.log(to);
//   if (to.path == "/error") {
//     next();
//   } else {
//     if (store.state.globalConfig.usageDefaultLanguage) {
//       next();
//     } else {
//       let linkUrl = location.origin;
//       let customToken = to.query.customToken;
//       initParams = to.params;

//       try {
//         const { data } = await getUsageId({ ...initParams, linkUrl, customToken });
//         if (data) {
//           store.commit("SetWikiAppId", data);
//           const { appId, usageSubdomain, usageThemeColor, usageFavicon, usageDefaultLanguage, usageManageDetails } = await store.dispatch("getWikiDetail");
//           // 设置主题颜色
//           updateThemeColor(usageThemeColor);
//           // 设置favicon
//           updateFavicon(usageFavicon);
//           // 获取当前语言下的detail
//           const wikiDetail = getDetailByDefaultLan(usageDefaultLanguage, usageManageDetails);
//           // 更细浏览器title
//           updateTitle(wikiDetail.usageName);
//           // 保存数据
//           store.commit("SetDefaultlanDetail", wikiDetail);
//           // 下一步

//           next("/" + appId + "/" + usageSubdomain);
//         } else {
//           next("/error");
//         }
//       } catch (error) {
//         next("/error");
//       }
//     }
//   }
// });

export default router;
