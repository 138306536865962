/*
 * @FileDescription:
 * @Author: ruokun Yin
 * @Date: 2022-11-18 14:36:38
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2022-11-21 15:35:27
 */
const req = require.context("./svg", false, /\.svg$/);
const requireAll = (requireContext) => requireContext.keys().map(requireContext);
requireAll(req);
