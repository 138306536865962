/*
 * @FileDescription:
 * @Author: ruokun Yin
 * @Date: 2022-12-07 16:14:43
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2024-01-04 15:57:31
 */
import request from "@/api/request";

/**
 * @description: 获取分类目录
 * @param {String}
 * @return []
 */
export function usageCategoryList(data) {
    return request({
        url: `/usage/usageCategory/usageHomeCategory`,
        method: "post",
        data,
    });
}

/**
 * @description: 获取详情
 * @param {String}
 * @return []
 */
export function getUsageDetailById(data) {
    return request({
        url: "/usage/usageManage/getUsageById",
        method: "post",
        data,
    });
}

/**
 * @description: 分类详情
 * @param {String}
 * @return []
 */
export function getUsageDetail(data) {
    return request({
        url: "/usage/usageCategory/usageCategoryDetail",
        method: "post",
        data: { ...data, type: 1 },
    });
}

/**
 * @description:  站点文章搜索
 * @data {String}  articleInfo
 * @data {String}  appId
 * @data {number}  usageManageId
 * @data {String}  language
 * @data {Strnumbering}  pageIndex
 * @data {number}  pageSize
 * @return []
 */
export function findArticle(data) {
    return request({
        url: "/usage/usageArticle/findArticle",
        method: "post",
        data,
    });
}

/**
 * @description:
 * @data {String} appId
 * @data {String} usageSubdomain
 * @data {String}  linkUrl
 * @return []
 */
export function getUsageId(data) {
    return request({
        url: "/usage/usageManage/getUsageId",
        method: "post",
        data,
    });
}

/**
 * @description: 获取文章详情
 * @data {String}  articleId
 * @data {String}  language
 * @data {String}  type
 * @return []
 */
export function getArticleDetail(data) {
    return request({
        url: "/wiki/wikiArticleManage/linkArticleDetail",
        method: "post",
        data,
    });
}

/**
 * @description: wiki站点导航
 * @data {Number}  usageManageId
 * @data {String}  articleId
 * @data {String}  language
 * @return []
 */
export function wikiArticleNav(data) {
    return request({
        url: "/usage/usageArticle/showUsageArticle",
        method: "post",
        data,
    });
}
