/*
 * @FileDescription:
 * @Author: ruokun Yin
 * @Date: 2022-11-22 16:17:07
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-05-18 19:10:58
 */

import store from "@/store";

// 判断设备是否为移动端的方法
export const isMobile = () => {
  console.log(11111, store.state.winWidth);
  if (store.state.winWidth <= 620) return true;
  return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
    navigator.userAgent
  );
};

// 修改站点主题颜色
export const updateThemeColor = (color = "#0066ff") => {
  document.documentElement.style.setProperty("--theme-color", color);
};

// 修改favicon
export const updateFavicon = (link) => {
  if (!link) return;
  let $favicon = document.querySelector('link[rel="icon"]');
  if ($favicon !== null) {
    $favicon.href = link;
  } else {
    $favicon = document.createElement("link");
    $favicon.rel = "icon";
    $favicon.href = link;
    document.head.appendChild($favicon);
  }
};

// 修站点的title
export const updateTitle = (title) => {
  if (!title) return;
  document.title = title;
};

// 根据默认语言获取配置
export const getDetailByDefaultLan = (defaltLan, usageManageDetails) => {
  if (usageManageDetails.length == 0) {
    return {};
  } else if (usageManageDetails.length === 1) {
    return usageManageDetails[0];
  } else if (usageManageDetails.length > 1) {
    let detail = usageManageDetails.filter((item) => item.usageLanguage == defaltLan);
    if (detail[0]) {
      return detail[0];
    } else {
      return {};
    }
  }
};
