<!--
 * @FileDescription: 
 * @Author: ruokun Yin
 * @Date: 2022-12-09 13:57:25
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2023-01-04 09:45:41
-->
<template>
  <div>
    <router-view :key="routerKey"></router-view>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      routerKey: "",
    };
  },
  computed: {},
  watch: {
    $route: {
      handler(val) {
        this.routerKey = val.path;
      },
      immediate: true,
    },
  },
};
</script>
