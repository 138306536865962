/*
 * @FileDescription:
 * @Author: ruokun Yin
 * @Date: 2022-11-17 15:30:56
 * @LastEditors: ruokun Yin
 * @LastEditTime: 2022-12-08 09:28:18
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utils/amfe-flexible";
import "normalize.css";
import "./style/base.scss";
import "@/icons"; // icons
import SvgIcon from "@/components/SvgIcon";
Vue.component("SvgIcon", SvgIcon);
import "@wangeditor/editor/dist/css/style.css";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);

import i18n from "@/locales"; // 多语言注册

// api 接口挂载
import * as api from "./api/index.js";
Vue.prototype.$api = api;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
